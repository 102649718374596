<template>
  <Layout>
    <PageHeader :title="title" :items="items" class="poppins fw-normal" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body table">
            <div class="main_header_ctas d-flex gap-2 flex-wrap align-items-center justify-content-between py-2">
              <div class="del_btn-wrapper">

<button
  v-if="multipuleIds.length >= 1"
  @click="deleteBulk"
  class="
    btn
    border-0
    px-5
    fw-bold
    text-white
    btn btn-primary
    waves-effect waves-light
    w-md
    primary-button
  "
>
  Delete All
</button>
</div>
            <div class="add_new">  <router-link to="/users/add"
           class="
             btn
             btn-primary
             border-0
             px-5
             fw-bold
             text-white
             btn btn-primary
             waves-effect waves-light
             w-md
             primary-button
           "
         >
           Add New User
            </router-link></div>
         </div>
         
            <div class="table-responsive">
              <table
                id="datatable"
                class="table table-centered table-nowrap mb-0"
              >
                <thead class="table-light">
                  <tr>
                    <th style="width: 20px">
                      <div class="form-check font-size-16">
                        <input
                          @change="selectAllRecord"
                          v-model="selectAll"
                          type="checkbox"
                          class="form-check-input"
                          id="customCheck1"
                        />
                        <label class="form-check-label" for="customCheck1"
                          >&nbsp;</label
                        >
                      </div>
                    </th>
                    <th>User Name</th>
                    <th>Contact Number</th>
                    <th>Email</th>
                    <th>Add Business</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr colspan="5" v-if="loading">
                    <td colspan="6">
                      <APILoader
                        :loading="loading"
                        class="align-middle text-center"
                      ></APILoader>
                    </td>
                  </tr>
                  <tr v-else v-for="(user, index) in users" v-bind:key="index">
                    <th style="width: 20px">
                      <div class="form-check font-size-16">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="multipuleIds"
                          :value="user.id"
                          id="customCheck1"
                        />
                        <label class="form-check-label" for="customCheck1"
                          >&nbsp;</label
                        >
                      </div>
                    </th>
                    <td>
                      <div class="userDetail">
                        <div class="d-flex align-items-center">
                          <!-- <img src="https://stampbox-project-dev.s3.us-east-2.amazonaws.com/users/user-26/item-media/16710125843991.jpg"
                          class="user-img me-2" alt="..." width="30px" height="30px">-->
                          <img v-if="!user.avatar"
                            src="../../../../../public/avatar.jpg"
                            class="user-img me-2"
                            alt="..."
                            width="30px"
                            height="30px"
                          />
                          <img v-else :src="user.avatar"  class="user-img me-2"
                            alt="..."
                            width="30px"
                            height="30px">
                          <span>{{ user.full_name }}</span>
                        </div>
                      </div>
                    </td>
                    <td>{{ user.mobile }}</td>
                    <td>{{ user.email }}</td>
                    <td>
                      <a href="/business/add" class="primary-color"
                        >Add Business</a
                      >
                    </td>
                    <td>
                      <div class="d-flex">
                        <button
                          style="margin: 10px"
                          @click="userUpdate(user.id)"
                          class="p-0 bg-transparent border-0"
                        >
                          <span class="text-primary">
                            <i class="uil-edit"></i>
                          </span>
                        </button>
                        <button
                          @click="deleteUser(user.id)"
                          class="p-0 bg-transparent border-0"
                        >
                          <span class="text-danger">
                            <i class="uil-trash-alt"></i>
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end table-responsive -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
  page: {
    title: "User Listing",
    meta: [
      {
        name: "add-user",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },

  data() {
    return {
      multipuleIds: [],
      selectAll: false,
      loading: true,
      users: null,
      links: [],
      title: "User Listing",
      items: [
        {
          text: "TipShare",
        },
        {
          text: "User Listing",
          active: true,
        },
      ],
    };
  },
  // mounted function
  mounted() {
    this.allUsers();
  },
  methods: {
    randerData(response) {
      // console.log(response.data.data);
      this.users = response.data.data;
      this.loading = false;
      if ($.fn.dataTable.isDataTable("#datatable")) {
        let table = $("#datatable").DataTable();
        table.destroy();
      }
      setTimeout(() => {
        $("#datatable").DataTable({
          ordering: false,
          lengthMenu: [
            [5, 10, 25, 50, -1],
            [5, 10, 25, 50, "All"],
          ],
          pageLength: 25,
        });
      });
    },

    allUsers() {
      this.$axios
        .get("all-users")
        .then((response) => {
          setInterval(this.randerData(response), 1500);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getRecords(link) {
      console.log(link);
    },
    // Delete user record
    async delete(id) {
      try {
        const {
          data: { status },
        } = await this.$axios.delete("delete-user/" + id);
        this.responseDelete = status;
      } catch (error) {
        console.log(error);
      }
    },
    // Alert Swal while deleting the record
    deleteUser(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.delete(id);
          let status = this.responseDelete;
          if (status == true) {
            Swal.fire("Deleted!", "Record has been deleted.", "success");
            this.allUsers();
          }
        }
      });
    },
    updateRecords(records) {
      this.users = records;
      this.links = records;
    },
    // Record update Function
    userUpdate: function (id) {
      this.$router.push({
        name: "update-user",
        params: { id: id },
      });
    },
    selectAllRecord() {
      if (this.selectAll) {
        let ids = [];
        this.users.forEach((element) => {
          ids.push(element.id);
        });
        this.multipuleIds = [...new Set(ids)];
        console.log(this.multipuleIds);
      } else {
        this.multipuleIds = [];

        console.log(this.multipuleIds);
      }
    },
    deleteBulk() {
      this.$axios
        .post("delete-user-bulk", { ids: this.multipuleIds })
        .then((response) => {
          this.allUsers();
          this.triggerSwal(response.data.message, "success");
          this.selectAll = false;
          this.multipuleIds = [];
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>